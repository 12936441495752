<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>View associations</h3>

	<p>An <i>association</i> indicates that two CASE items—an <i>origin</i> item and a <i>destination</i> item—have some relationship to each other.</p>
	<ul>
		<li>The most common association type (other than <code>isChildOf</code> associations, which define the framework hierarchy) is <code>isRelatedTo</code>, which is simply a generic indication that the origin and destination are related in some way.</li>
		<li>For any given association, the origin and destination items may both be in the same framework where the association is defined (an <i>inter</i>-framework association), or either the origin or the destination may be in a different framework (an <i>inter</i>-framework association).</li>
		<li>
			More help information is available about the technical specification for associations and help with <span v-html="link('create_assoc', 'creating associations')"></span>.
		</li>
	</ul>
	<p class="k-no-clear">To view framework associations, use the <nobr><v-icon small>fa-solid fa-left-right</v-icon></nobr> Association Options icon in the toolbar to filter your Tree View to the association(s) you’d like to see. 
		
		<img srcset="/docimages/view_associations.png 2x " class="k-help-img block">
	<p>Once enabled, you will see the Associations icon throughout the  tree view mode:
		<li>If you see an <nobr><v-icon small>fa-solid fa-left-right</v-icon></nobr> Association icon next to an item, it means that the item (or its descendants) has an association with another item.</li>
		<li>If the item is directly associated with another item, you will also see a number indicating how many associations the item has. (If you don’t see a number, it means that the item has descendants that have associations, but the item itself does not.)</li>
		<li>When you click on an item, any associations to or from the clicked item will be shown in the <span v-html="link('item_card', 'item&apos;s card')"></span>.</li>
		<li>In the screenshot above, the item has two associations. The color of each association indicates the framework where the associated item comes from; in this case, “ELAGSERL4” comes from the current framework (brown), while “ELD-LA.K.Inform.Expressive” comes from a different framework (green).</li>
		<li>Click the EXPAND button to view more information about the associations right in the item card:</li>
		<li>And click an association to view the associated item in context, with the associated framework on the right side of the window and a line connecting the two associated items:</li>
	</p>
	<p><strong>NOTE:</strong>Associations can also be viewed from the <span v-html="link('tableassociationsview', 'association table')"></span>; you can export association data from there.</p>
	</p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	